import { parseHTMLFromString } from '../../../helpers';

export const abandonmentTriggerCallback = function(e) {
	const mouseY = e.clientY;
	if (mouseY < 50 && document.querySelector('#abandonment:empty')) {
		document.body.classList.add('no-scroll');
		// Load Abadonment Popup Content
		const url = window.modForm.opts.abandonmentHtmlPath;
		fetch(url).then((response) => response.text()).then(function(htmlString) {
			const parsedHtml = parseHTMLFromString(htmlString);
			document.querySelector('#abandonment').appendChild(parsedHtml);
		});
		document.querySelector('body').removeEventListener('mouseleave', abandonmentTriggerCallback);
	}
};
